require('../scss/styles.scss');
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

jQuery(document).ready(function (jQuery) {
	// Elements to inject
	// var elementsToInject = document.querySelectorAll('svg[data-src]');
	var elementsToInject = document.querySelectorAll('.inject-me');
	// Options
	var injectorOptions = {
		evalScripts: 'once',
		pngFallback: 'image/fallback'
	};
	// Trigger the injection
	var injector = new SVGInjector(injectorOptions);
	injector.inject(
		elementsToInject
	);

	var textarea = document.querySelector('.form-material textarea');
	if(jQuery(textarea).length) {
		jQuery(textarea).attr('rows', 1);
		textarea.addEventListener('keydown', autosize);
	}
	
	function autosize() {
		var el = this;
		setTimeout(function() {
			el.style.cssText = 'height:' + el.scrollHeight + 'px';
		},0);
	}
});